import {defineStore} from 'pinia'

export const useSlideOverStore = defineStore('slideOver', {

    state: () => ({
        isSlideOverOpen: false,
        title: '',
        translationParams: null,
        description: '',
        component: '',
        context: null,
        size: 'lg'
    }),

    actions: {
        setSlideOverOpen(isSlideOverOpen) {
            this.isSlideOverOpen = isSlideOverOpen
        },
        setTitle(title, translationParams) {
            this.title = title
            if (translationParams) {
                this.translationParams = translationParams
            }
        },
        setDescription(description) {
            this.description = description
        },
        setComponent(component) {
            this.component = component
        },
        setContext(context) {
            this.context = context
        },
        setSize(size) {
            this.size = size
        },
        resetSlideOver() {
            this.isSlideOverOpen = false
            this.title = ''
            this.translationParams = null
            this.description = ''
            this.component = ''
            this.context = null
            this.size = 'lg'
        }
    }
})